<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<mat-drawer-container [class.z-index--auto]="isStatusOpened" [hasBackdrop]="true">
  <mat-drawer-content [class.z-index--999]="isStatusOpened">
    
    <app-skeleton-posts [isNewPost]="true" *ngIf="loadingPost"  [dashboardType]="dashboardType"></app-skeleton-posts>

    <ng-container *ngIf="!loadingPost">

      <mat-drawer #statusDrawer mode="'over'" position="end" [ngClass]="{'top-space': isImpersonating || (!isMember && (subscription$ | async)?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL) }"
      (keydown.escape)="closeStatus(false, statusDrawer)">
        <app-post-management-status
          [isSliderOpen]="isStatusOpened"
          [statusData]="statusData"
          [statusDrawer]="statusDrawer"
          [postTitle]="postTitle"
          [postId]="postId"
          (closeStatusSlider)="closeStatus($event, statusDrawer)"
        ></app-post-management-status>
      </mat-drawer>

      <div class="mt--15" *ngIf="dashboardType !== 'ARCHIVE'">
        <section class="col-9 d--inl-block">
          <p class="txt--14 d--inl-block txt--gray fw--medium ">Filters</p>

          <mat-select class="input-group__field p--10 w--18 ml--15 mr--15" placeholder="Post Occurence" [value]="ocurrenceFilterValue" (selectionChange)="changedOccurenceFilter($event)" [disabled]="isLoading">
            <mat-option [value]="'null'">All Recurrence Types</mat-option>
            <mat-option [value]="false">Nonrecurring</mat-option>
            <mat-option [value]="true">Recurring</mat-option>
          </mat-select>

          <mat-select class="input-group__field p--10 w--18" placeholder="Post Status" [value]="statusFilterValue" (selectionChange)="changedStatusFilter($event)" [disabled]="isLoading">
            <mat-option [value]="'all'">All Post Statuses</mat-option>
            <mat-option [value]="'DRAFT.DRAFT'">Drafts</mat-option>
            <mat-option [value]="'ACTIVE.PARTIAL'">Partial</mat-option>
            <mat-option [value]="'ACTIVE.SUCCESS'">Published</mat-option>
          </mat-select>
        </section>

        <section class="col-3 d--inl-block txt--align--end" *ngIf="countOfSelectedItems > 0">
          <p class="txt--14 txt--lighter-black d--inl-block">{{countOfSelectedItems}} items selected</p>
          <button mat-flat-button color="warn" class="btn btn-icon buttons-actions ml--20" (click)="deletePosts(selectedPosts, true)">
            Delete Selected
          </button>
        </section>

        </div>

      <table mat-table [dataSource]="dataSource" class="table table--lg"> 
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef class="pt--20 pb--20" [class.row]="dashboardType !== 'ARCHIVE'">
            <strong *ngIf="dashboardType === 'ARCHIVE'">Media</strong>
            <section *ngIf="dashboardType !== 'ARCHIVE'">
              <mat-checkbox class="mt--5 d--inl-block" [(ngModel)]="allPostChecked" (change)="toggleCheckAll($event)" [disabled]="!dataSource?.data?.length || isDeleteButtonEnabled"></mat-checkbox>
              <strong>Post</strong>
            </section>
          </th>
          
          <td mat-cell *matCellDef="let element" class="pl--25 mt--20 mb--30 pt--20 pb--20 vertical--align--top" [class.w--40]="dashboardType === 'LOCAL_POST'" [ngClass]="{'highlight-cell-yellow': isSelected(element), 'highlight-cell-blue': shouldHighlightCell(element)}">
            <section class="row mr--0 pull--left">
              <mat-checkbox *ngIf="dashboardType !== 'ARCHIVE'"
                class="ml--15 mt--35"
                [id]="element.id"
                [(ngModel)]="element.isChecked"
                [disabled]="(!isBulkPost && element?.scheduleRules?.isBulk) || element.bulkStatus ==='FLAGGED_FOR_DELETE'"
                (change)="selectPost(element, $event)"
              ></mat-checkbox>
              <div>
                <div class="table__img" *ngIf="element?.post?.media; else noMedia">
                  <img class="img_post" alt=""
                    [src]="element.post.media[0]?.sourceUrl ? element.post.media[0].sourceUrl : '../../../assets/images/placeholder/NoImage.png' "
                  >
                  <div id="overlay">
                    <p style="margin-top: 6px; margin-right: 10px; text-align: end;">{{element.post.media?.length}} <em class="fas fa-images"></em></p>
                  </div>
                </div>
                <ng-template #noMedia>
                    <div class="table__img txt--center d-flex align-items-center j--content--center">
                      No image
                    </div>
                </ng-template>
              </div>
            </section>

            <section class="ml--30 pull--left container--75" [class.container--65]="!isBulkPost">
              <p class="d--inl-block label--blue fw--semibold txt--sm mr--10 p--5 pl--10 pr--10">{{element?.post?.topicType == 'STANDARD' ? 'UPDATE' : element?.post?.topicType | uppercase}}</p>
              <p *ngIf="element?.scheduleRules?.endDate" class="d--inl-block txt--sm label--red "><span class="fw--semibold">Schedule to delete on:</span> {{element?.scheduleRules?.endDate | date: 'MMM d, yyyy'}}</p>
              <p class="d--block txt--lighter-black txt--md pt--10 pb--10">{{element?.post?.title}}</p>
              <div class="mb--15">
                <read-more class="mt--10 overflow--wrap--anywhere txt--lighter-black txt--14" [text]="element?.post?.summary" [showToggleButton]="true" [maxLength]="217"></read-more>
              </div>
            </section>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="description" *ngIf="dashboardType === 'ARCHIVE'">
          <th mat-header-cell *matHeaderCellDef><strong>Description</strong></th>
          <td mat-cell *matCellDef="let element" class="pt--20 vertical--align--top" [ngClass]="{'highlight-cell-yellow': isSelected(element), 'highlight-cell-blue': shouldHighlightCell(element)}">
            <section class="pull--left container--75" [class.container--65]="!isBulkPost">
              <p class="d--inl-block txt--sm mr--10 label--blue fw--semibold p--5 pl--10 pr--10">{{element?.post?.topicType == 'STANDARD' ? 'UPDATE' : element?.post?.topicType | uppercase}}</p>
              <p *ngIf="element?.scheduleRules?.endDate" class="d--inl-block txt--sm label--red"><span class="fw--semibold">Schedule to delete on:</span> {{element?.scheduleRules?.endDate | date: 'MMM d, yyyy'}}</p>
              <p class="d--block txt--lighter-black fw--700 txt--md pt--10 pb--10">{{element?.post?.title}}</p>
              <div class="mb--15">
                <read-more class="mt--10 overflow--wrap--anywhere txt--lighter-black txt--14" [text]="element?.post?.summary" [showToggleButton]="true" [maxLength]="217"></read-more>
              </div>
            </section>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef><strong>Frequency</strong></th>
          <td mat-cell *matCellDef="let element" class="pt--20 w--15 vertical--align--top" [ngClass]="{'highlight-cell-yellow': isSelected(element), 'highlight-cell-blue': shouldHighlightCell(element)}">
            <p *ngIf="element?.scheduleRules?.isRecurring" class="d--inl-block txt--sm mr--10 label--blue fw--semibold p--5 pl--10 pr--10">Recurring</p>
            <p class="txt--14 txt--lighter-black pt--10 pb--10">{{element?.scheduleRules?.type === 'INSTANT' ? 'Does not repeat' : element?.scheduleRules?.type | titlecase}}</p>
            <p class="txt--14 txt--gray">{{getRecurringData(element)}}</p>
          </td>
        </ng-container>


        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="w--17" [class.w--30]="dashboardType === 'ARCHIVE'"><strong>Post Date (Last Updated)</strong></th>
          <td mat-cell *matCellDef="let element" class="vertical--align--top pt--20" [ngClass]="{'highlight-cell-yellow': isSelected(element), 'highlight-cell-blue': shouldHighlightCell(element)}">
            <div *ngIf="element?.bulkStatus === 'DRAFT'">
              <ng-container *ngIf="element?.createdAt">
                <p class="txt--dark--gray fw--medium txt--14">Draft Created On:</p>
                <p class="txt--lighter-black txt--14 mb--15">{{(element?.createdAt | date: "MMM d, yyyy") || 'Never'}}</p>
              </ng-container>
            </div>
            
            <div *ngIf="element?.bulkStatus !== 'DRAFT' && element?.scheduleRules?.isRecurring">
              <p class="txt--dark--gray fw--medium txt--14">Last Published On:</p>
              <p class="txt--lighter-black txt--14 mb--15">{{(element?.metadata?.lastPublishedOn | date: "MMM d, yyyy") || 'Never'}}</p>
              
              <div *ngIf="element?.bulkStatus !== 'SUCCESS' && element?.metadata?.nextScheduleDate">
                <p class="txt--dark--gray fw--medium txt--14">Next Post Scheduled At:</p>
                <p class="txt--lighter-black txt--14">{{(element?.metadata?.nextScheduleDate | date: "MMM d, yyyy")}}</p>
              </div>
            </div>
    
            <div *ngIf="element?.bulkStatus !== 'DRAFT' && !element?.scheduleRules?.isRecurring">
              <p class="txt--dark--gray fw--medium txt--14">Last Published On:</p>
              <p class="txt--lighter-black txt--14 mb--15">{{(element?.metadata?.lastPublishedOn | date: "MMM d, yyyy") || 'Never'}}</p>
    
              <p class="txt--dark--gray fw--medium txt--14">Last Update On:</p>
              <p class="txt--lighter-black txt--14">{{(element?.updatedAt | date: "MMM d, yyyy") || 'Never'}}</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Status</strong></th>
          <td mat-cell *matCellDef="let element" class="txt--center vertical--align--top pt--20" [ngClass]="{'highlight-cell-yellow': isSelected(element), 'highlight-cell-blue': shouldHighlightCell(element)}">
            <p [ngClass]="getStatusClass(element?.bulkStatus)">
              {{statusLabel(element?.bulkStatus) | titlecase}}
              <a class="txt--sm txt--gray' cursor--pointer d--block mt--5 fw--regular"
               *ngIf="isBulkPost && dashboardType !== 'ARCHIVE' && element?.bulkStatus !== 'FLAGGED_FOR_DELETE'" (click)="openStatus(element, statusDrawer)">View Details</a>

              <button mat-icon-button class="btn btn-icon status-button w--20 h--20" (click)="infoAboutStatus(element)"
              *ngIf="!isBulkPost && (element?.bulkStatus === 'FAILED' || (element?.hasFailedTask && (element?.bulkStatus === 'ACTIVE' || element?.bulkStatus === 'SUCCESS')))">
                <em class="fas fa-question-circle icon-fail"></em>
              </button>
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" *ngIf="dashboardType !== 'ARCHIVE'"> 
          <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Post Actions</strong></th>
          <td mat-cell *matCellDef="let element" class="txt--center vertical--align--top pt--20" [ngClass]="{'highlight-cell-yellow': isSelected(element), 'highlight-cell-blue': shouldHighlightCell(element)}">

            <section *ngIf="isBulkPost || (!isBulkPost && !element?.scheduleRules?.isBulk)" [class.align-buttons]="!isBulkPost">
              <!-- removed postDrawer as an argument for edit() as it was failing -->
              <button *ngIf="!element?.scheduleRules?.isBulk && element?.bulkStatus == 'FAILED'" mat-flat-button color="primary" class="btn btn-icon mr--10"
              (click)="retryPost(element)">
              <em class="fas fa-redo"></em>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon mr--10" [disabled]="element?.pendingChange !== null || element.bulkStatus === 'FLAGGED_FOR_DELETE'" (click)="edit(element)"
              [matTooltip]="element?.pendingChange !== null ? 'This post has pending changes' : null" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white">
                <em class="fas fa-pen"></em>
              </button>

              <span class="alternate-theme">
                <button mat-flat-button color="warn" class="btn btn-icon mr--10" [disabled]="element.bulkStatus === 'FLAGGED_FOR_DELETE'" (click)="copyPost(element)">
                  <em class="fas fa-copy"></em>
                </button>
              </span>
              <button mat-flat-button color="warn" class="btn btn-icon buttons-actions mr--20" [disabled]="element.bulkStatus ==='FLAGGED_FOR_DELETE'" (click)="deletePosts(element, false)">
                <em class="far fa-trash-alt"></em>
              </button>
            </section>

            <p *ngIf="!isBulkPost && element?.scheduleRules?.isBulk" class="txt--sm">
              This post can only be managed in Bulk Post because it applies to multiple locations.
              <a class="cursor--pointer txt--lightblue txt--underline" [routerLink]="['/posts-management']">Go to bulk</a>
            </p>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="no-hover"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="no-hover"></tr>
      </table>

      <app-no-data message="{{noDataMessage?.message}}" description="{{noDataMessage?.description}}" *ngIf="!dataSource?.data?.length"></app-no-data>

      <app-paginator [pagination]="pagination" *ngIf="pagination && !loadingPost" (reload)="handleReload($event)"></app-paginator>

    </ng-container>

  </mat-drawer-content>
</mat-drawer-container>