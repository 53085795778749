import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

import { MULTIKEY_METRIC } from '../../../constants/google/location-stats';
import { MULTILOCATIONS_INFO_DATA } from './../../../constants/google/location-stats';

@Component({
  selector: 'app-skeleton-table-multilocations',
  templateUrl: './skeleton-table-multilocations.component.html'
})
export class SkeletonTableMultilocationsComponent implements OnInit {

  @Input() isInfoTable? = false;

  metrics = [];

  constructor() { }

  ngOnInit() {
    if (this.isInfoTable) {
      MULTILOCATIONS_INFO_DATA.forEach(metric => {
        this.metrics.push({ id: _.camelCase(metric.name), name: metric.name});
      });
      this.metrics.unshift({ id: 'location', name: 'Location' });
    } else {
      MULTIKEY_METRIC.forEach(metric => {
        this.metrics.push({ id: _.camelCase(metric.name), name: metric.name});
      });
      this.metrics = [ { id: 'location', name: 'Location' }, ..._.reject( this.metrics, { 'id': 'averageStarRating' })];
    }
  }

}
