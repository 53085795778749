import { __awaiter, __generator, __read, __spread, __values } from "tslib";
// dep
import { Location } from '@angular/common';
import { EventEmitter, OnDestroy, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, ViewRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take, debounceTime, map, switchMap, startWith } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
import moment from 'moment';
import * as _ from 'lodash';
import { LocationService } from '../../services/location.service';
import { ReportService } from '../../services/report.service';
import { ModalService } from '../../services/modal.service';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { periodDaysQanda } from '../../constants/periodDaysQanda';
import { PdfService } from '../../services/pdf.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { IS_IMPERSONATING } from '../../constants/session';
import { MULTIKEY_METRIC, MULTIKEY_METRIC_INSIGHTS } from '../../constants/google/location-stats';
import { alwaysShowCalendar } from '../../helpers/syncfusion.helpers';
import { VAR_MONTH_PICKER_YEAR_AGO } from '../../constants/datePicker';
import { DatesService } from '../../services/dates.service';
import { QuestionsAnswerService } from '../../services/questions-answer.service';
import { makeOpenPromise } from '../../helpers/utils.helpers';
var ReportsFilterComponent = /** @class */ (function () {
    function ReportsFilterComponent(reportS, _cdRef, _locationService, _modalS, _route, _snackS, _pdfS, _auth, _router, _location, _dateS, _questionAnswerS) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        this.reportS = reportS;
        this._cdRef = _cdRef;
        this._locationService = _locationService;
        this._modalS = _modalS;
        this._route = _route;
        this._snackS = _snackS;
        this._pdfS = _pdfS;
        this._auth = _auth;
        this._router = _router;
        this._location = _location;
        this._dateS = _dateS;
        this._questionAnswerS = _questionAnswerS;
        this.isImpersonating = false;
        this.reportName = '';
        this.reportType = 'keyword';
        this.lockDates = false;
        this.hasPeriodSelect = false;
        this.hasToggle = false;
        this.isComparisonVisible = false;
        this.hasFoodOrdering = true;
        this.maxDate = null;
        this.minDate = null;
        this.subscribeToDatepicker = false;
        this.showTooltipsOnDisabledDates = false;
        this.hasDateLimitsInfo = false;
        this.aggregation = null;
        this.comparisonMetrics = [];
        this.stats = [];
        this.multiChartSelected = [];
        this.hasComparison = false;
        this.questionsTypeReport = null;
        this.compareToValue = null;
        this.periodReportValue = null;
        this.isReport = false;
        this.accounts = [];
        this.hasClearValues = false;
        this.hasSaveValues = false;
        this.questionsType = new EventEmitter();
        this.questionsPeriod = new EventEmitter();
        this.locationsChanged = new EventEmitter();
        this.keywordSearch = new EventEmitter();
        this.change = new EventEmitter();
        this.selectRange = new EventEmitter();
        this.shared = new EventEmitter(); // TODO: Not used?
        this.export = new EventEmitter();
        this.isExporting = new EventEmitter();
        this.statusCheck = new EventEmitter();
        this.refresh = new EventEmitter();
        this.expand = new EventEmitter();
        this.changedViewComparison = new EventEmitter();
        this.clearValuesEmit = new EventEmitter();
        this.saveValuesEmit = new EventEmitter();
        this.montPicker = VAR_MONTH_PICKER_YEAR_AGO;
        this.viewModeChecked = 'new';
        this.checkedList = [];
        this.selectedDateA$ = new BehaviorSubject(null);
        this.locationP = makeOpenPromise();
        this.locationWasSent$ = new Subject();
        /**  Must limit the start date of the date picker? */
        this.minRange = false;
        this.topBanner = false;
        this.isLegacyInsights = false;
        this.monthSelected = '';
        this.GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
        this.selectRangeOptions = [
            { value: null, displayName: 'None', disabled: false },
            { value: 'prevPeriodComparison', displayName: 'Previous Period', disabled: false },
            { value: 'prevMonthComparison', displayName: 'Previous Month', disabled: false },
            { value: 'prevYearComparison', displayName: 'Previous Year', disabled: false },
        ];
        this.selectRangeValue = this.selectRangeOptions[0];
        this.locationsFiltered = [];
        this.questionsTypeOptions = [
            { value: null, displayName: 'All' },
            { value: 'withAnswerFromOwner', displayName: 'With Answer' },
            { value: 'withoutAnswerFromOwner', displayName: 'Without Answer' },
        ];
        this.questionsTypeValue = this.questionsTypeOptions[0];
        this.periodDaysOptions = periodDaysQanda;
        this.periodDaysValue = this.periodDaysOptions[3];
        this.filterLocationControl = new FormControl('');
        this.locationsOptions = [];
        this.labelsLocationsFiltered = null;
        this.keywordSearchInput = new FormControl();
        this.keywordSubject = new Subject();
        this.capturing = false;
        this._subscriptionsToCleanup = [];
        this._firstLoad = true;
        this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
        this.subscriptionOut$ = this._auth.subscription$;
        this._subscriptionsToCleanup.push(this.subscriptionOut$.subscribe(function (sub) {
            if (sub)
                _this._checkPlanLimits();
        }));
        this.isLegacyInsights = !(((_c = (_b = (_a = this._route) === null || _a === void 0 ? void 0 : _a.snapshot) === null || _b === void 0 ? void 0 : _b.routeConfig) === null || _c === void 0 ? void 0 : _c.path) == 'insights' ||
            ((_f = (_e = (_d = this._route) === null || _d === void 0 ? void 0 : _d.snapshot) === null || _e === void 0 ? void 0 : _e.routeConfig) === null || _f === void 0 ? void 0 : _f.path) == 'reviews' ||
            ((_j = (_h = (_g = this._route) === null || _g === void 0 ? void 0 : _g.snapshot) === null || _h === void 0 ? void 0 : _h.params) === null || _j === void 0 ? void 0 : _j.reportType) == 'review' || ((_o = (_m = (_l = (_k = this._route) === null || _k === void 0 ? void 0 : _k.snapshot) === null || _l === void 0 ? void 0 : _l.params) === null || _m === void 0 ? void 0 : _m.reportType) === null || _o === void 0 ? void 0 : _o.includes('performance')));
        this.maxDate = this.isLegacyInsights ? moment('12-31-2022', 'MM-DD-YYYY') : null;
        // checkbox
        this.allChecked = false;
        this.checkItem = true;
        this.metrics = this.isLegacyInsights ? MULTIKEY_METRIC : MULTIKEY_METRIC_INSIGHTS;
    }
    Object.defineProperty(ReportsFilterComponent.prototype, "selectedDate", {
        set: function (selectedDate) {
            if (this._firstLoad)
                this.selectedDateA$.next(selectedDate);
        },
        enumerable: true,
        configurable: true
    });
    ;
    ReportsFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (this.reportType != 'keyword') {
            this.maxDate = this.maxDate ? moment(this.maxDate, 'MM-DD-YYYY') : (this.isLegacyInsights ? moment('12-31-2022', 'MM-DD-YYYY') : null);
            this.minDate = this.minDate ? moment(this.minDate, 'MM-DD-YYYY') : null;
        }
        else {
            this.maxDate = ((_a = this.maxDate) === null || _a === void 0 ? void 0 : _a.toDate()) || this.montPicker.lastEnd;
            this.minDate = ((_b = this.minDate) === null || _b === void 0 ? void 0 : _b.toDate()) || null;
            this.selectedDateA = {
                start: ((_c = this.selectedDateA) === null || _c === void 0 ? void 0 : _c.start.isBefore(moment(this.minDate))) ? moment(this.minDate) : (_d = this.selectedDateA) === null || _d === void 0 ? void 0 : _d.start,
                end: ((_e = this.selectedDateA) === null || _e === void 0 ? void 0 : _e.end.isAfter(moment(this.maxDate))) ? moment(this.maxDate) : (_f = this.selectedDateA) === null || _f === void 0 ? void 0 : _f.end
            };
            if (this.compareToValue)
                this.selectRangeValue = this.compareToValue;
            this.changeComparison();
            if ((_g = this.reportType) === null || _g === void 0 ? void 0 : _g.includes('rollup'))
                this.getMetricsCustomChart();
        }
        this.locationsOptions = __spread(this.accounts);
        this.locationsOptions.forEach(function (acc) { return acc.locations.forEach(function (loc) { return loc.checked = false; }); });
        this.isLocationReport = !!((_j = (_h = this._route.snapshot) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.isReport);
        if (this.reportType.includes('qanda') && this.isReport && this.questionsTypeReport)
            this.questionsTypeValue = this.questionsTypeOptions.find(function (el) { return el.value == _this.questionsTypeReport; });
        var loc = this.locations[0];
        if (loc && loc.accountId && loc.locationId)
            this._fetchLocation(((_k = this._auth.session) === null || _k === void 0 ? void 0 : _k.gid) || this._route.snapshot.params.gid, loc.accountId, loc.locationId);
        this.isSharedUrl = _.endsWith(this._router.url, 'shared');
        this.selectedDateA$
            .pipe(take(2))
            .subscribe(function (selectedDateA) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var dateToStr = function (date) {
                return (moment.isMoment(date) ? _this._dateS.getStringDateYMD(date) : _this.createDateFromString(date).toISOString());
            };
            _this.selectedDateA = selectedDateA;
            if (selectedDateA) {
                _this.dataPicker = {
                    aggregation: _this.aggregation,
                    range: {
                        start: dateToStr(selectedDateA.start),
                        end: dateToStr(selectedDateA.end),
                    }
                };
                if (_this.selectedDateB)
                    _this.dataPicker.rangeB = {
                        start: dateToStr(_this.selectedDateB.start),
                        end: dateToStr(_this.selectedDateB.end)
                    };
                _this.ejsStartDate = moment.isMoment((_a = selectedDateA) === null || _a === void 0 ? void 0 : _a.start) ? (_c = (_b = selectedDateA) === null || _b === void 0 ? void 0 : _b.start) === null || _c === void 0 ? void 0 : _c.toDate() : _this.createDateFromString((_d = selectedDateA) === null || _d === void 0 ? void 0 : _d.start);
                _this.ejsEndDate = moment.isMoment((_e = selectedDateA) === null || _e === void 0 ? void 0 : _e.end) ? (_g = (_f = selectedDateA) === null || _f === void 0 ? void 0 : _f.end) === null || _g === void 0 ? void 0 : _g.toDate() : _this.createDateFromString((_h = selectedDateA) === null || _h === void 0 ? void 0 : _h.end);
                _this.change.emit(_this.dataPicker);
                _this._firstLoad = false;
                _this.getEnabledOptionsFromRange();
                _this.detectChanges();
                return;
            }
        }, function (error) {
            console.error(error);
            var now = moment().utc(true);
            _this.dataPicker = {
                aggregation: _this.aggregation,
                range: {
                    start: now.clone().subtract('3', 'months').toISOString(),
                    end: now.clone().toISOString()
                },
                multiChart: []
            };
            _this._firstLoad = false;
            _this.getEnabledOptionsFromRange();
            _this.detectChanges();
        });
        if (this.isReport)
            this.periodDaysValue = this.periodDaysOptions.find(function (el) { return el.value == _this.periodReportValue; });
        this._subscriptionsToCleanup.push(this.keywordSearchInput.valueChanges.subscribe(function (keyword) { _this.keywordSubject.next(keyword || ''); }));
        this.keywordSubject.pipe(debounceTime(650), switchMap(function (keyword) {
            _this.keywordSearch.next(keyword || '');
            return keyword;
        }));
        // TODO: Redundant, remove or finish
        // this.accounts = this.buildFilterOptions();
        this.filteredOptions = this.filterLocationControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value || ''); }));
    };
    ReportsFilterComponent.prototype._checkPlanLimits = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loc, loc_is_free, sub_is_trial;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.locationP];
                    case 1:
                        loc = _a.sent();
                        loc_is_free = (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                            loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL);
                        return [4 /*yield*/, this.subscriptionOut$.getValue()];
                    case 2:
                        sub_is_trial = ((_a.sent()).status === GROUP_SUBSCRIPTION_TYPE.TRIAL);
                        this.topBanner = !!(this._route.snapshot.parent.params.locationId && (sub_is_trial || loc_is_free));
                        // Restrict the DatePicker minimum range?
                        this.minRange = (!sub_is_trial && loc_is_free);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsFilterComponent.prototype.createDateFromString = function (date) {
        var _a = __read(date.split(date.includes('T') ? 'T' : ' ')[0].split('-').map(Number), 3), year = _a[0], month = _a[1], day = _a[2];
        return new Date(year, month - 1, day);
    };
    ReportsFilterComponent.prototype.detectChanges = function () {
        if (this._cdRef && !this._cdRef.destroyed)
            this._cdRef.detectChanges();
    };
    // TODO: Dummy method, remove
    // buildFilterOptions(options = this.accounts) {
    //   const data = options
    //   return data;
    // }
    ReportsFilterComponent.prototype.getMetricsCustomChart = function () {
        var _a;
        var data = this.metrics.filter(function (el) { return el.name != 'Average Star Rating' && el.name != 'Number of New Reviews'; });
        var statsMetrics = [];
        if ((_a = this.stats) === null || _a === void 0 ? void 0 : _a.length) {
            this.stats.forEach(function (s) { return Object.keys(s).filter(function (el) {
                var _a;
                if (el != 'title' && el != 'totals' && el != 'labels') {
                    statsMetrics.push((_a = el) === null || _a === void 0 ? void 0 : _a.toUpperCase());
                }
            }); });
            if (!this.hasFoodOrdering) {
                var index = data.findIndex(function (el) { return el.key == "FOOD_ORDERING"; });
                data.splice(index, 1);
            }
            data = data.filter(function (item) { return statsMetrics.includes(item.key); });
        }
        this.metricsCustomChart = data;
        this.initMultiChart();
    };
    ReportsFilterComponent.prototype.initMultiChart = function () {
        var _a, _b;
        var metricsSelected = (_a = this.multiChartSelected) === null || _a === void 0 ? void 0 : _a.map(function (m) { return m.key; });
        (_b = this.metricsCustomChart) === null || _b === void 0 ? void 0 : _b.forEach(function (m) {
            var _a, _b;
            if ((_a = metricsSelected) === null || _a === void 0 ? void 0 : _a.includes((_b = m) === null || _b === void 0 ? void 0 : _b.key)) {
                m.checked = true;
            }
        });
    };
    ReportsFilterComponent.prototype.ngOnChanges = function (changes) {
        if ('hasFoodOrdering' in changes) {
            this.getMetricsCustomChart();
        }
        if (changes.stats) {
            this.detectChanges();
        }
        if (changes.selectedDateFromDashboard) {
            var date = changes.selectedDateFromDashboard.currentValue;
            if (date) {
                var start = this._dateS.getStringDateYMD(date.start);
                var end = this._dateS.getStringDateYMD(date.end);
                this.dataPicker = {
                    aggregation: this.aggregation,
                    range: {
                        start: moment.isMoment(date.start) ? start : this.createDateFromString(date.start).toISOString(),
                        end: moment.isMoment(date.end) ? end : this.createDateFromString(date.end).toISOString()
                    }
                };
                this.selectedDateA$.next(date);
                this.change.emit(this.dataPicker);
            }
        }
        this.getEnabledOptionsFromRange();
    };
    ReportsFilterComponent.prototype.monthlyRangeSelected = function (event) {
        var _a, _b, _c, _d;
        if (this.monthSelected === event.text)
            return;
        this.monthSelected = event.text;
        if (this.isLocationReport) {
            if (this.reportType === 'keyword') {
                this.dataPicker.isReport = true;
                this.dataPicker.range.start = moment((_a = event) === null || _a === void 0 ? void 0 : _a.startDate);
                this.dataPicker.range.end = moment((_b = event) === null || _b === void 0 ? void 0 : _b.endDate);
                this.getEnabledOptionsFromRange();
                this.change.emit(this.dataPicker);
                this.detectChanges();
            }
        }
        else { // not report
            this.dataPicker = {
                aggregation: event.daySpan <= 365 ? 'month' : 'year',
                range: {
                    start: moment(event.startDate).utc(true),
                    end: moment(event.endDate).utc(true)
                }
            };
            if (this.reportType == 'keyword') {
                this.dataPicker.range.start = moment((_c = event) === null || _c === void 0 ? void 0 : _c.startDate);
                this.dataPicker.range.end = moment((_d = event) === null || _d === void 0 ? void 0 : _d.endDate);
            }
            this.getEnabledOptionsFromRange();
            this.selectRange.emit(this.selectRangeValue);
            this.change.emit(this.dataPicker);
            this.detectChanges();
        }
    };
    ReportsFilterComponent.prototype.daterangepickerOpen = function () {
        alwaysShowCalendar();
    };
    ReportsFilterComponent.prototype.getEnabledOptionsFromRange = function () {
        // if(!this.hasComparison) 
        //   return
        var _a, _b, _c, _d, _e, _f;
        if (!((_b = (_a = this.dataPicker) === null || _a === void 0 ? void 0 : _a.range) === null || _b === void 0 ? void 0 : _b.start) || ((_d = (_c = this.dataPicker) === null || _c === void 0 ? void 0 : _c.range) === null || _d === void 0 ? void 0 : _d.end)) {
            return;
        }
        var start = moment(this.dataPicker.range.start);
        var end = moment(this.dataPicker.range.end);
        var diff = end.diff(start, 'month');
        if (diff > 0) {
            this.selectRangeOptions[2].disabled = true;
            this.selectRangeValue = ((_e = this.selectRangeValue) === null || _e === void 0 ? void 0 : _e.value) !== "prevMonthComparison" ? this.selectRangeValue : this.selectRangeOptions[1];
        }
        else {
            this.selectRangeOptions[2].disabled = false;
        }
        if (diff > 11) {
            this.selectRangeOptions[3].disabled = true;
            this.selectRangeValue = ((_f = this.selectRangeValue) === null || _f === void 0 ? void 0 : _f.value) !== "prevYearComparison" ? this.selectRangeValue : this.selectRangeOptions[1];
        }
        else {
            this.selectRangeOptions[3].disabled = false;
        }
        this.detectChanges();
    };
    ReportsFilterComponent.prototype.GoBack = function (reportType) {
        if (reportType === 'comparison') {
            this._router.navigate(['/comparison-report']);
        }
        else if (reportType === 'performance-comparison') {
            this._router.navigate(['/performance-comparison-report']);
        }
        else if (reportType === 'rollup-report') {
            this._router.navigate(['/rollup-report']);
        }
        else if (reportType === 'performance-rollup') {
            this._router.navigate(['/performance-rollup-report']);
        }
        else if (reportType === 'Review Assistant') {
            this._router.navigate(['/review-assistant'], { queryParams: { tab: 2 } });
        }
        else {
            this._location.back();
        }
    };
    ReportsFilterComponent.prototype.GoLocations = function () {
        this._router.navigate(['/accounts', this.accountId, 'locations']);
    };
    ReportsFilterComponent.prototype.ngOnDestroy = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this._subscriptionsToCleanup), _c = _b.next(); !_c.done; _c = _b.next()) {
                var s = _c.value;
                s.unsubscribe();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.keywordSubject.complete();
        this.selectedDateA$.complete();
    };
    // check/uncheck all checkbox
    ReportsFilterComponent.prototype.toggleCheckAll = function () {
        var e_2, _a;
        try {
            for (var _b = __values(this.metrics), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                item.checked = this.allChecked;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.getCheckedItemList();
    };
    // if every item is checked, check the Select All checkbox
    ReportsFilterComponent.prototype.isAllChecked = function () {
        this.allChecked = !this.metricsCustomChart.find(function (m) { return !m.checked; });
        this.getCheckedItemList();
    };
    ReportsFilterComponent.prototype.unCheckedAll = function () {
        this.metrics.forEach(function (item) {
            item.checked = false;
        });
        this.getCheckedItemList();
    };
    ReportsFilterComponent.prototype.getCheckedItemList = function () {
        this.checkedList = this.metrics.filter(function (item) { return item.checked; });
    };
    ReportsFilterComponent.prototype.changeText = function (aggregation) {
        this.dataPicker.aggregation = aggregation;
        this.change.emit(this.dataPicker);
        this.detectChanges();
    };
    ReportsFilterComponent.prototype.changeSelectRangeValue = function (event) {
        this.selectRangeValue = event;
        this.changeComparison();
        this.selectRange.emit(event);
    };
    ReportsFilterComponent.prototype.getDate = function (date) {
        return date.split('T')[0];
    };
    ReportsFilterComponent.prototype.datesUpdated = function ($event) {
        var start = moment($event.range.start).utc(true);
        var end = moment($event.range.end).utc(true);
        var diff = end.diff(start, 'days') + 1;
        this.dataPicker.aggregation = (this.aggregation || this.reportS.correctDateAggregate($event));
        this.change.emit(this.dataPicker);
        this.detectChanges();
    };
    ReportsFilterComponent.prototype.handleMultiChart = function () {
        this.dataPicker.multiChart = this.checkedList;
        this.trigger.closeMenu();
        this.statusCheck.emit(false);
        this.change.emit(this.dataPicker);
        this.detectChanges();
    };
    ReportsFilterComponent.prototype.isComparisonReportMore25Locations = function () {
        var _a, _b, _c, _d;
        var charts = document.getElementById('charts-container');
        var chartArray = (_c = (_b = (_a = charts) === null || _a === void 0 ? void 0 : _a.childNodes[5]) === null || _b === void 0 ? void 0 : _b.childNodes[1]) === null || _c === void 0 ? void 0 : _c.childNodes[2];
        return (this.reportType === 'performance-comparison' && ((_d = chartArray) === null || _d === void 0 ? void 0 : _d.childElementCount) > 25);
    };
    ReportsFilterComponent.prototype.disableScrollingAndPointerEvents = function () {
        document.body.classList.add('disable-scroll');
        document.body.classList.add('disable-pointer-events');
        this.capturing = true;
    };
    ReportsFilterComponent.prototype.enableScrollingAndPointerEvents = function () {
        document.body.classList.remove('disable-scroll');
        document.body.classList.remove('disable-pointer-events');
        this.capturing = false;
    };
    ReportsFilterComponent.prototype.handleExport = function (format) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 7, 8]);
                        this.disableScrollingAndPointerEvents();
                        if (!(format === 'pdf')) return [3 /*break*/, 5];
                        this.scrollTop();
                        this.isExporting.emit(true);
                        this.expand.emit(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.exportPdf()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error("Error exporting PDF", e_3);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        if (format === 'csv' || format === 'csv-review')
                            this.export.emit(format);
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        this.enableScrollingAndPointerEvents();
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ReportsFilterComponent.prototype.exportPdf = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var params, filename, report, name_1, loc, location_1, address;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 6, 7]);
                        this.progress = true;
                        params = this._route.snapshot.params;
                        filename = void 0;
                        if (!params.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.reportS.getById(params.gid, params.id).get().toPromise()];
                    case 1:
                        report = (_b.sent()).data();
                        name_1 = ((_a = report) === null || _a === void 0 ? void 0 : _a.reportName) ? report.reportName : this.reportName;
                        filename = name_1.toLowerCase() + " - " + this.reportType.toUpperCase();
                        return [3 /*break*/, 4];
                    case 2:
                        loc = this.locations[0];
                        return [4 /*yield*/, this._locationService.fetchLocation(this._auth.session.gid, loc.accountId, loc.locationId)];
                    case 3:
                        location_1 = _b.sent();
                        address = this._locationService.formatAddress(location_1.location.address);
                        filename = "" + location_1.locationName + (address ? " - " + address : '');
                        _b.label = 4;
                    case 4: 
                    // const branding = await this._wlService.branding
                    return [4 /*yield*/, this._pdfS.base64AndSave('charts-container', filename, '')];
                    case 5:
                        // const branding = await this._wlService.branding
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        this.isExporting.emit(false);
                        this.progress = false;
                        this.enableScrollingAndPointerEvents();
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ReportsFilterComponent.prototype.handleShared = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
        this.dataPicker.locations = this.locations;
        var start;
        var end;
        var reportType = (((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) && !this.isLegacyInsights ? 'performance-insights' :
            !this._router.url.includes('report') ? this.reportType + "-location" :
                ((_b = this.reportType) === null || _b === void 0 ? void 0 : _b.includes('revenue')) && this._router.url.includes('performance') ? 'performance-revenue-report' : this.reportType + "-report");
        if (this.reportType.includes('qanda')) {
            var dates = this._questionAnswerS.buildPeriodDate(this.periodDaysValue.value);
            start = dates.startDatetime;
            end = dates.endDatetime;
        }
        else {
            start = moment.isMoment((_d = (_c = this.dataPicker) === null || _c === void 0 ? void 0 : _c.range) === null || _d === void 0 ? void 0 : _d.start) ? this._dateS.getStringDateYMD((_f = (_e = this.dataPicker) === null || _e === void 0 ? void 0 : _e.range) === null || _f === void 0 ? void 0 : _f.start) : (_h = (_g = this.dataPicker) === null || _g === void 0 ? void 0 : _g.range) === null || _h === void 0 ? void 0 : _h.start;
            end = moment.isMoment((_k = (_j = this.dataPicker) === null || _j === void 0 ? void 0 : _j.range) === null || _k === void 0 ? void 0 : _k.end) ? this._dateS.getStringDateYMD((_m = (_l = this.dataPicker) === null || _l === void 0 ? void 0 : _l.range) === null || _m === void 0 ? void 0 : _m.end) : (_p = (_o = this.dataPicker) === null || _o === void 0 ? void 0 : _o.range) === null || _p === void 0 ? void 0 : _p.end;
        }
        this.dataPicker.range.start = this.reportS.getFormattedStringDate(start);
        this.dataPicker.range.end = this.reportS.getFormattedStringDate(end);
        if (this.reportType.includes('comparison')) {
            var startB = moment.isMoment((_r = (_q = this.dataPicker) === null || _q === void 0 ? void 0 : _q.rangeB) === null || _r === void 0 ? void 0 : _r.start) ? this._dateS.getStringDateYMD((_t = (_s = this.dataPicker) === null || _s === void 0 ? void 0 : _s.rangeB) === null || _t === void 0 ? void 0 : _t.start) : (_v = (_u = this.dataPicker) === null || _u === void 0 ? void 0 : _u.rangeB) === null || _v === void 0 ? void 0 : _v.start;
            var endB = moment.isMoment((_x = (_w = this.dataPicker) === null || _w === void 0 ? void 0 : _w.rangeB) === null || _x === void 0 ? void 0 : _x.end) ? this._dateS.getStringDateYMD((_z = (_y = this.dataPicker) === null || _y === void 0 ? void 0 : _y.rangeB) === null || _z === void 0 ? void 0 : _z.end) : (_1 = (_0 = this.dataPicker) === null || _0 === void 0 ? void 0 : _0.rangeB) === null || _1 === void 0 ? void 0 : _1.end;
            this.dataPicker.rangeB = { start: null, end: null };
            this.dataPicker.rangeB.start = this.reportS.getFormattedStringDate(startB);
            this.dataPicker.rangeB.end = this.reportS.getFormattedStringDate(endB);
        }
        this._modalS.openGenericModal(ModalShareComponent, {
            createNew: true,
            dataPicker: this.dataPicker,
            reportType: reportType,
            comparisonMetrics: this.comparisonMetrics,
            showComparison: this.reportType == 'keyword' ? this.hasComparison : null,
            compareToValue: this.reportType == 'keyword' ? this.selectRangeValue : null,
            questionsType: this.reportType == 'qanda' ? (_2 = this.questionsTypeValue) === null || _2 === void 0 ? void 0 : _2.value : null,
            periodDaysValue: this.reportType == 'qanda' ? (_3 = this.periodDaysValue) === null || _3 === void 0 ? void 0 : _3.value : null,
            reportName: this.reportName,
            route: this._route
        }, function () {
            _this.detectChanges();
        });
    };
    ReportsFilterComponent.prototype.handleRefresh = function () {
        var _this = this;
        this.locations.forEach(function (l) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._snackS.openSuccess('Refresh request sent! ', 2000);
                        return [4 /*yield*/, this._locationService.checkLocation(this._auth.session.gid, l.accountId, l.locationId).toPromise()];
                    case 1:
                        _b.sent();
                        _a = this.reportType;
                        switch (_a) {
                            case 'rollup': return [3 /*break*/, 2];
                            case 'review': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 2: return [4 /*yield*/, this._locationService.saveInsights(l.accountId, l.locationId).toPromise()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this._locationService.saveReviews(l.accountId, l.locationId).toPromise()];
                    case 4:
                        _b.sent();
                        this.refresh.emit();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, this._locationService.saveReviews(l.accountId, l.locationId).toPromise()];
                    case 6:
                        _b.sent();
                        this.refresh.emit();
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    ReportsFilterComponent.prototype.scrollTop = function () {
        (function goUp() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(goUp);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    };
    ReportsFilterComponent.prototype.goPerformanceInsights = function () {
        var p = this._route.parent.snapshot.params;
        this._router.navigateByUrl("/account/" + p.accountId + "/location/" + p.locationId + "/insights");
    };
    ReportsFilterComponent.prototype.changeQuestionsTypeValue = function (option) {
        this.questionsTypeValue = option;
        this.questionsType.emit(option);
    };
    ReportsFilterComponent.prototype.changeQuestionsPeriodValue = function (option) {
        this.periodDaysValue = option;
        this.questionsPeriod.emit(option);
    };
    ReportsFilterComponent.prototype.locationsFilterdChange = function (menu, clear) {
        if (clear) {
            this.locationsFiltered = [];
            this.accounts.forEach(function (acc) { return acc.locations.forEach(function (l) { return l.checked = false; }); });
            this.labelsLocationsFiltered = null;
        }
        this.locationsChanged.emit(this.locationsFiltered);
        this.filterLocationControl.setValue(null);
        menu.close.next(false);
    };
    ReportsFilterComponent.prototype.getLocationsFiltered = function (event, account, location) {
        var _this = this;
        var value = [];
        this.labelsLocationsFiltered = null;
        this.accounts.forEach(function (acc) {
            acc.locations.forEach(function (loc) {
                loc.checked = (loc.locationId == location.locationId ? event.checked : loc.checked);
                if (loc.checked) {
                    value.push({ location: loc, gid: acc.gid });
                    _this.labelsLocationsFiltered = _this.labelsLocationsFiltered ? _this.labelsLocationsFiltered + " - " + loc.locationName : loc.locationName;
                }
            });
        });
        this.locationsFiltered = this.buildLocationsFiltered(value);
    };
    ReportsFilterComponent.prototype.buildLocationsFiltered = function (options) {
        var data = [];
        options.forEach(function (el) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
            var indexAcc = data.findIndex(function (locfiltered) { var _a, _b; return locfiltered.accountId == ((_b = (_a = el) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.accountId); });
            if (indexAcc > -1) {
                data[indexAcc].locations.push({
                    accountId: (_b = (_a = el) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.accountId,
                    address: (_d = (_c = el) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.address,
                    locationId: (_f = (_e = el) === null || _e === void 0 ? void 0 : _e.location) === null || _f === void 0 ? void 0 : _f.locationId,
                    locationName: (_h = (_g = el) === null || _g === void 0 ? void 0 : _g.location) === null || _h === void 0 ? void 0 : _h.locationName,
                    checked: (_k = (_j = el) === null || _j === void 0 ? void 0 : _j.location) === null || _k === void 0 ? void 0 : _k.checked,
                    serviceArea: (_m = (_l = el) === null || _l === void 0 ? void 0 : _l.location) === null || _m === void 0 ? void 0 : _m.serviceArea,
                });
            }
            else {
                data.push({
                    accountId: (_p = (_o = el) === null || _o === void 0 ? void 0 : _o.location) === null || _p === void 0 ? void 0 : _p.accountId,
                    gid: (_q = el) === null || _q === void 0 ? void 0 : _q.gid,
                    locations: [{
                            accountId: (_s = (_r = el) === null || _r === void 0 ? void 0 : _r.location) === null || _s === void 0 ? void 0 : _s.accountId,
                            address: (_u = (_t = el) === null || _t === void 0 ? void 0 : _t.location) === null || _u === void 0 ? void 0 : _u.address,
                            locationId: (_w = (_v = el) === null || _v === void 0 ? void 0 : _v.location) === null || _w === void 0 ? void 0 : _w.locationId,
                            locationName: (_y = (_x = el) === null || _x === void 0 ? void 0 : _x.location) === null || _y === void 0 ? void 0 : _y.locationName,
                            checked: (_0 = (_z = el) === null || _z === void 0 ? void 0 : _z.location) === null || _0 === void 0 ? void 0 : _0.checked,
                            serviceArea: (_2 = (_1 = el) === null || _1 === void 0 ? void 0 : _1.location) === null || _2 === void 0 ? void 0 : _2.serviceArea,
                        }]
                });
            }
        });
        return data;
    };
    ReportsFilterComponent.prototype.changeComparison = function () {
        var _a;
        this.hasComparison = !!((_a = this.selectRangeValue) === null || _a === void 0 ? void 0 : _a.value);
        this.changedViewComparison.emit(this.hasComparison);
    };
    ReportsFilterComponent.prototype._filter = function (value) {
        var _a, _b;
        var options = [];
        var filterValue = (_a = value) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (value != '') {
            (_b = this.accounts) === null || _b === void 0 ? void 0 : _b.forEach(function (acc) {
                var _a, _b;
                (_b = (_a = acc) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.forEach(function (loc) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                    if (((_a = loc) === null || _a === void 0 ? void 0 : _a.locationName.toLowerCase().includes(filterValue)) || ((_c = (_b = loc) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.administrativeArea.toLowerCase().includes(filterValue)) || ((_e = (_d = loc) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.languageCode.toLowerCase().includes(filterValue)) || ((_g = (_f = loc) === null || _f === void 0 ? void 0 : _f.address) === null || _g === void 0 ? void 0 : _g.locality.toLowerCase().includes(filterValue)) || ((_j = (_h = loc) === null || _h === void 0 ? void 0 : _h.address) === null || _j === void 0 ? void 0 : _j.postalCode.toLowerCase().includes(filterValue)) || ((_l = (_k = loc) === null || _k === void 0 ? void 0 : _k.address) === null || _l === void 0 ? void 0 : _l.regionCode.toLowerCase().includes(filterValue)) || ((_p = (_o = (_m = loc) === null || _m === void 0 ? void 0 : _m.address) === null || _o === void 0 ? void 0 : _o.addressLines) === null || _p === void 0 ? void 0 : _p.join('').toLowerCase().includes(filterValue))) {
                        options.push({
                            gid: (_q = acc) === null || _q === void 0 ? void 0 : _q.gid,
                            location: loc
                        });
                    }
                });
            });
            this.locationsOptions = this.buildLocationsFiltered(options);
        }
        else {
            this.locationsOptions = __spread(this.accounts);
        }
    };
    ReportsFilterComponent.prototype.hasLastMonth = function () {
        var _a;
        return (!this.maxDate || moment(this.maxDate).isAfter(moment((_a = this.montPicker) === null || _a === void 0 ? void 0 : _a.lastEnd)));
    };
    ReportsFilterComponent.prototype.clearValues = function () {
        this.clearValuesEmit.emit();
    };
    ReportsFilterComponent.prototype.saveValues = function () {
        this.saveValuesEmit.emit();
    };
    ReportsFilterComponent.prototype._fetchLocation = function (gid, accountId, locationId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var loc, e_4;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._locationService.fetchLocation(gid, accountId, locationId)];
                    case 1:
                        loc = _c.sent();
                        this.locationP.resolve(loc);
                        this.locationWasSent$.next(((_a = loc['lastImpressions-fastTask']) === null || _a === void 0 ? void 0 : _a.status) === 'SENT' &&
                            ((_b = loc['lastKeywords-fastTask']) === null || _b === void 0 ? void 0 : _b.status) === 'SENT');
                        this._checkPlanLimits();
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _c.sent();
                        this.locationP.reject(e_4);
                        this.locationWasSent$.next(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ReportsFilterComponent;
}());
export { ReportsFilterComponent };
