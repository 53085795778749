// dep
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

// app
import {ModalConfirmData} from "../classes/modal-confirm-data";
import { WhiteLabelService } from '../services/white-label.service';


@Component({
  selector: 'app-confirm',
  template: `
  <div class="dialog__header txt--center"
      [ngClass]="{'dialog__header--warn':  data.alertType == 1, 
                  'dialog__header--info':  data.alertType == 0, 
                  'dialog__header--error': data.alertType == 2}">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title }}</strong></h2>
  </div>
  
	<div class="dialog__content dialog__content--height" mat-dialog-content>
		<div class="dialog-row" [ngClass]="{'div-center': data.modalPayment}">
      <p class="txt--lg txt--center m--0" [ngClass]="{'text-payment': data.modalPayment}">
        <strong>{{ data.content }}</strong></p>
      <p class="txt--lg txt--center m--0">{{ data.description }}</p>
		</div>
  </div>

  <div mat-dialog-actions class="dialog__footer" [ngClass]="{'div-center': data.modalPayment}">
    <div class="alternate-theme d-inline-block mr--10" [ngClass]="{'hidden-content': data.modalPayment}">
      <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel">{{ data.closeButtonLabel }}</button>
      <a *ngIf="data.alertType == 2" class="cursor--pointer txt--underline link-support" [href]="wl.supportMailURL">
        <span>Contact Support</span>
      </a>
    </div>
    <button tabIndex="-1" mat-flat-button [color]="data.alertType == 1 || data.alertType == 2 ? 'warn' : 'accent'" class="btn btn--action" [mat-dialog-close]="true">{{ data.confirmButtonLabel }}</button>
  </div>

  <div class="dialog__header content-terms-labels txt--center" 
       [ngClass]="{'dialog__header--warn'  : data.alertType == 1, 
                   'dialog__header--info'  : data.alertType == 0, 
                   'dialog__header--error' : data.alertType == 2, 
                   'hidden-content'        : !data.typePaid}">
    <div>
      <a class="mr--10 cursor--pointer" (click)="wl.goToPaymentPolicy()">Payment Policy</a> | 
      <a class="ml--10 cursor--pointer" (click)="wl.goToPrivacyPolicy()">Privacy Policy</a>
    </div>
    <div class="mt--10">Powered by
      <svg class="powerBy-Icon" xmlns="http://www.w3.org/2000/svg" width="55" height="20" viewBox="5.322 20.344 440.334 180.144"><path d="M406.487 86.49c-8.87 0-7.944 10.902-8.312 22.541h14.779c0-13.119 0-22.541-6.467-22.541zm-8.312 41.572c0 10.53 5.172 12.562 13.856 12.562 9.978 0 23.463-2.958 28.637-3.881v25.499c-4.063 1.479-16.442 4.986-28.637 4.986-21.987 0-48.78-3.51-48.78-50.438 0-41.387 21.064-51.179 43.235-51.179 22.727 0 39.17 11.64 39.17 50.809v11.642h-47.481zm-86.669-36.584c-2.03 0-6.096 1.663-8.312 2.958v47.296c1.661.557 4.618.926 5.912.926 6.098 0 9.793-5.174 9.793-26.79-.002-21.988-1.666-24.39-7.393-24.39zm4.064 75.752c-4.434 0-9.053-1.293-12.378-2.033v35.29h-35.105V67.459h28.452l5.175 6.097c5.357-4.803 12.934-7.945 20.878-7.945 14.41 0 31.408 4.064 31.408 50.254 0 50.628-25.496 51.365-38.43 51.365zM235.747 55.45c-9.791 0-17.366-7.945-17.366-17.553s7.575-17.553 17.366-17.553c9.794 0 17.369 7.945 17.369 17.553s-7.575 17.553-17.369 17.553zm-17.551 109.933V67.459h35.105v97.924h-35.105zM196.94 94.989c-5.543 0-9.793 2.218-11.641 3.326v67.068h-35.104V67.459h27.899l5.173 9.422c2.032-6.097 7.575-11.271 15.705-11.271 5.543 0 9.238 1.109 10.899 2.032v29.191c-3.51-.921-8.128-1.844-12.931-1.844zm-66.707 45.636c2.217 0 9.978-1.107 12.562-1.479v24.943c-5.356 1.292-17.182 3.141-24.941 3.141-9.978 0-28.638-1.107-28.638-28.267V89.816H76.283V67.459h12.935l4.434-22.171 30.67-7.39V67.46h18.476l-4.618 22.357h-13.857v42.865c-.002 6.649 2.03 7.943 5.91 7.943zM39.505 95.728c0 3.88 2.588 5.173 8.684 7.944l3.696 1.664c8.683 3.88 20.691 10.162 20.691 28.639 0 29.561-21.987 33.256-37.691 33.256-10.162 0-20.878-2.402-28.084-4.617V137.3c6.281 1.666 17.184 4.249 21.986 4.249 5.358 0 9.423-.737 9.423-5.542 0-3.695-2.587-5.357-8.314-7.943l-4.435-2.033c-8.313-3.881-20.139-10.53-20.139-28.453 0-26.237 20.508-31.963 37.692-31.963 12.934 0 21.433 2.955 25.866 4.619v24.573c-5.174-1.662-15.703-3.88-21.985-3.88-4.249-.002-7.39 1.105-7.39 4.801z"/></svg>
    </div>
  </div>
  `
})
export class ConfirmComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalConfirmData,
    public wl: WhiteLabelService
  ) {
  }

}

