<form>
  <div class="dialog__content dialog__content--height" mat-dialog-content>
    <div class="row no-gutters">
      <div class="col-md-12 mb--10">
        <mat-label class="d-block mb--10">Protocol Name</mat-label>
        <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
          <input matInput placeholder="Enter name here" [formControl]="name" required class="input-group__field">
          <mat-error *ngIf="name.errors">
            <p *ngIf="name.errors['required']">Name is required</p>
            <p *ngIf="name.errors['minlength']">Min 3 characters</p>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 border-bottom mb--20">
        <h3 class="txt--black mb--10"><strong>Triggers</strong></h3>
      </div>

      <div class="col-md-8">
        <div class="row no-gutters">
          <div class="col-6">
            <p>With Comments</p>
            <p>Without Comments</p>
            <p>Time Delay</p>
          </div>
          <div class="col-6">
            <div class="checkbox-group checkbox-group--margin mb--15">
              <mat-checkbox [formControl]="withComment" (change)="withoutsChange()"></mat-checkbox>
            </div>

            <div class="checkbox-group checkbox-group--margin mb--15">
              <mat-checkbox [formControl]="withOutComment" (change)="withoutsChange()" ></mat-checkbox>
            </div>

            <div class="row no-gutters align-items-center">
              <div class="col-7">
                <div class="input-number-wrapper input-number-wrapper--sm">
                  <div class="input-number--arrows">
                    <span class="arrow arrow-up" (click)="triggerNumberUp($event)"><i class="fas fa-angle-up"></i></span>
                    <span class="arrow arrow-down" (click)="triggerNumberDown($event)"><i class="fas fa-angle-down"></i></span>
                  </div>
                  <input type="number" [formControl]="delay" min="0">
                </div>
              </div>
              <div class="col-5">
                <mat-label class="d-block ml--10">days</mat-label>
              </div>
              <mat-error *ngIf="delay.errors">
                <p>The number must be grater than 0.</p>
              </mat-error>
            </div>


          </div>
        </div>
      </div>

      <div class="col-md-4">
        <p class="mb--10">Star Rating</p>
        <div class="stars--xs stars-checkbox--margin">
          <mat-checkbox (change)="checkedStars($event,i)" class="d-flex align-items-center"
                        *ngFor="let s of starsArray;index as i" [checked]="selectedStars.includes(i+1)">
            <app-starts [starts]="s" [showEmpty]="false"></app-starts>
          </mat-checkbox>
          <mat-error class="mat-error--absolute" *ngIf="stars.errors">
            <p>Stars are required</p>
          </mat-error>
        </div>
      </div>
    </div>
    <div class="col-12 mb--10" *ngIf="showKeywords">

      <p style="font-weight: bold;">Trigger Keywords</p>

      <ng-container *ngIf="keywords.length">
        <div *ngFor="let form of keywords.controls; index as i" class="d-flex align-items-center">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input matInput placeholder="Keyword {{i+1}}" [formControl]="form" class="input-group__field" (keydown.enter)="$event.preventDefault()"/>
            <mat-error class="mat-error--absolute" *ngIf="inputKeyword(i)?.hasComma">
              <p>The keyword {{i + 1}} can't contain a comma</p>
            </mat-error>
            <mat-error class="mat-error--absolute" *ngIf="inputKeyword(i)?.required">
              <p>The keyword {{i + 1}} is required or must be only one word</p>
            </mat-error>
          </mat-form-field>
          <span class="alternate-theme">
            <button mat-flat-button color="primary" (click)="removeKeyword(i)" class="btn btn-icon btn--rounded ml--10 mb--20">-</button>
          </span>
        </div>
      </ng-container>
      <app-button [alternative]="true" (click)="pushKeyword()">+ Add New</app-button>

    </div>

    <div class="col-12 mb--10" *ngIf="showKeywords">

      <p style="font-weight: bold;">Exclude Keywords</p>

      <ng-container *ngIf="excludeKeywords && excludeKeywords.length">
        <div *ngFor="let form of excludeKeywords.controls; index as i" class="d-flex align-items-center">
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input matInput placeholder="Keyword {{i+1}}" [formControl]="form" class="input-group__field" (keydown.enter)="$event.preventDefault()"/>
            <mat-error class="mat-error--absolute" *ngIf="inputNegativeKeyword(i)?.hasComma">
              <p>The exclude keyword {{i + 1}} can't contain a comma</p>
            </mat-error>
            <mat-error class="mat-error--absolute" *ngIf="inputNegativeKeyword(i)?.required">
              <p>The exclude keyword {{i + 1}} is required or must be only one word</p>
            </mat-error>
          </mat-form-field>
          <span class="alternate-theme">
            <button mat-flat-button color="primary" (click)="removeNegativeKeyword(i)" class="btn btn-icon btn--rounded ml--10 mb--20">-</button>
          </span>
        </div>
      </ng-container>
      <app-button [alternative]="true" (click)="pushNegativeKeyword()">+ Add New</app-button>

    </div>

  </div>
</form>
