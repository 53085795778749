import { __assign } from "tslib";
import { COLORS_CHART } from './../../../constants/chart-color';
import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { COLORS_CHART_PROGRESS_REVENUE } from '../../../constants/chart-color';
var ChartStatsComponent = /** @class */ (function () {
    function ChartStatsComponent(cdr) {
        this.cdr = cdr;
        this.line = true;
        this.colors = [];
        this.reportType = null;
        this.dataset = [];
    }
    ChartStatsComponent.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    ChartStatsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b, _c;
        switch (this.stats.title) {
            case 'Primary Actions': {
                this.titleIcon = 'fa-mouse-pointer';
                break;
            }
            case 'Secondary Actions': {
                this.titleIcon = 'fa-mouse-pointer';
                break;
            }
            case 'Views By Device': {
                this.titleIcon = 'fa-search reverse-icon';
                break;
            }
            case 'Actions on your Google Business Profile': {
                this.titleIcon = 'fa-mouse-pointer';
                break;
            }
            case 'Photo views': {
                this.titleIcon = 'fa-image';
                break;
            }
            case 'Photo quantity': {
                this.titleIcon = 'fa-image';
                break;
            }
            case 'total views on search': {
                this.titleIcon = 'fa-eye';
                break;
            }
            case 'Search impressions': {
                this.titleIcon = 'fa-search reverse-icon';
                break;
            }
            case 'Google Posts Activity': {
                this.titleIcon = 'fa-star';
                break;
            }
            default: {
                break;
            }
        }
        Object.keys(this.stats).forEach(function (key, i) {
            if (key !== 'totals' && key !== 'title' && key !== 'labels') {
                var stat = {};
                if (_.isEqual(_this.type, 'switch-colors') || _.isEqual(_this.type, 'vertical')) {
                    stat = __assign(__assign({}, _this.stats[key]), { backgroundColor: COLORS_CHART_PROGRESS_REVENUE[i], pointHitRadius: 0 });
                }
                else {
                    stat = __assign(__assign({}, _this.stats[key]), { backgroundColor: COLORS_CHART[i], pointHitRadius: 0 });
                }
                _this.dataset.push(stat);
            }
        });
        if (((_a = this.dataset[0]) === null || _a === void 0 ? void 0 : _a.label) === 'Mobile Phone Calls' &&
            ((_b = this.dataset[1]) === null || _b === void 0 ? void 0 : _b.label) === 'Direction Requests' &&
            ((_c = this.dataset[2]) === null || _c === void 0 ? void 0 : _c.label) === 'Website Visits') {
            this.dataset.sort(function (a, b) {
                return a.label.localeCompare(b.label);
            });
        }
    };
    return ChartStatsComponent;
}());
export { ChartStatsComponent };
