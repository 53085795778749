
<ng-container *ngIf="type!='revenue'; else vertical">
  <div class="mb--15">
    <p class="txt--black fw--medium mb--5">
      {{label}}
      <span *ngIf="tooltip"
      matTooltip={{tooltip}}
      matTooltipPosition="above"
      matTooltipClass="mat-tooltip--override"
      class="mat-tooltip tooltip tooltip--info">
      <em class="fas fa-info"></em>
    </span>
    </p>
    <div #progressBarFill [id]="'progress-bar-' + index"  class="progress-label progress-label--normal {{ progressBgColorClass }} ">
      <mat-progress-bar mode="determinate" [value]="total?.percent"></mat-progress-bar>
      <span class="progress-label__txt txt--sm txt--lightgray fw--light {{ progressColorClass}}">
      {{total?.numeric | number:  '1.0-0'}} <span *ngIf="showPercent">({{total?.percent | number:  '1.0-0'}}%)</span>
    </span>
    </div>
  </div>
</ng-container>

<ng-template #vertical>
  <div class="progress-wrapper pt--20">

    <div class="progress--absolute">
      <div #progressBarFill [id]="'progress-bar-' + index" class="progress-label progress-label--normal {{ progressBgColorClass }} ">
        <mat-progress-bar mode="determinate" [value]="total?.percent"></mat-progress-bar>
      </div>
    </div>
  </div>
  <div class="txt--center">
      <span class="progress-label__txt txt--sm txt--lightgray fw--light {{ progressColorClass}}">
      ({{total?.numeric | number:  '1.0-0'}})</span>
    <p class="txt--black fw--medium mb--5">{{label}}</p>
  </div>
</ng-template>
