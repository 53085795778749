import { OnInit, OnChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
var ChartProgressComponent = /** @class */ (function () {
    function ChartProgressComponent(cdr) {
        this.cdr = cdr;
        this.showPercent = true;
        this.tooltip = null;
    }
    ChartProgressComponent.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    ChartProgressComponent.prototype.ngOnInit = function () {
        var _a, _b;
        this.showPercent = !((_a = this.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('post'));
        this.progressColorClass = (((_b = this.total) === null || _b === void 0 ? void 0 : _b.percent) > 55) ? 'txt--white' : 'txt--black';
    };
    ChartProgressComponent.prototype.ngOnChanges = function () {
        this.setBackgroundColor(this.bgColor);
    };
    ChartProgressComponent.prototype.setBackgroundColor = function (color) {
        var css = "\n      #progress-bar-" + this.index + " .mat-progress-bar-fill::after {\n        background-color: " + color + ";\n      }\n    ";
        var style = document.createElement('style');
        style.append(document.createTextNode(css));
        document.head.appendChild(style);
    };
    return ChartProgressComponent;
}());
export { ChartProgressComponent };
